import useAxios from "axios-hooks";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { DateTime } from "luxon";
import { ChecklistState } from "./reducer";
import { SupportedMediaFormatsType } from "../../react-components/FileUpload/FileUpload";
import { Guid } from "../../system/guid";

const BASE_CHECKLIST_URL = environment.dependencies.checklist_api.url + "/api";
const CREATION_URL = BASE_CHECKLIST_URL + "/checklist";
export const CHECKLIST_HANDELING_URL = BASE_CHECKLIST_URL + "/session";
export const FILEUPLOAD_URL =
  environment.dependencies.fileupload_api.url + "/api/fileupload";

export const BASE_CHECKLIST_MEDIA_PATH = "checklist";

export function sortSchedulesByDate(
  schedules: any[],
  key: string,
  order: "ASC" | "DESC" = "ASC"
) {
  const c = order === "ASC" ? 1 : -1;
  return schedules.sort(
    (a, b) =>
      c *
      (DateTime.fromISO(a[key]).toMillis() -
        DateTime.fromISO(b[key]).toMillis())
  );
}

export type ChecklistSumary = {
  assignedId: string;
  name: string;
  image: string;
  tags: string[];
  checklistId: string;
  schedulingEnabled: boolean;
  isAllwaysSchedule: boolean;
}

export const mapAllChecklists = (evs: any, tags: string[] = null): ChecklistSumary[] => {
  const events = [...evs].sort(timestampSorterAsc);

  const assignedEvents = events.filter((obj) => obj.type === "AssignedToSite").reverse();
  const describedEvents = events.filter((obj) => obj.type === "Described").reverse();
  const siteDescribedEvents = events.filter(
    (obj) => obj.type === "SiteDescribed"
  ).reverse();
  const mediaChecklistEvents = events.filter(
    (obj) => obj.type === "AddMediaChecklist"
  ).reverse();

  const allScheduleCreatedEvents = events.filter(
    (obj) => (obj.type === "ScheduleCreated" || obj.type === "AllwaysScheduleCreated")
  ).reverse();

  const checklists = assignedEvents.map((e) => {
    const scheduleCreatedEvents = allScheduleCreatedEvents.filter(obj => obj.assignedId === e.assignedId);
    console.log(scheduleCreatedEvents);

    const describedEvent = describedEvents
      .find((obj) => obj.checklistId == e.checklistId);

    const siteDescribedEvent = siteDescribedEvents
      .find((obj) => obj.assignedId === e.assignedId);

    const mediaChecklistEvent = mediaChecklistEvents
      .find((obj) => obj.checklistId == e.checklistId);

    const scheduleCreatedEvent = scheduleCreatedEvents
      .find((obj) => obj.checklistId == e.checklistId);

    const checklistTags = mapTagEventsToTags(
      events.filter(
        (ev) =>
          ev.checklistId === e.checklistId &&
          (ev.type === "Tagged" || ev.type === "Untagged")
      )
    );

    return {
      assignedId: e.assignedId,
      name: siteDescribedEvent?.name || describedEvent?.name,
      image: mediaChecklistEvent?.imagePath
        ? `${FILEUPLOAD_URL}/${mediaChecklistEvent?.imagePath}`
        : "",
      tags: checklistTags,
      checklistId: e.checklistId,
      schedulingEnabled: scheduleCreatedEvent?.schedulingEnabled,
      isAllwaysSchedule: scheduleCreatedEvent?.type === "AllwaysScheduleCreated"
    };
  });

  return !!tags
    ? checklists.filter((checklist) => hasOneOfTags(tags, checklist.tags))
    : checklists;
};


const compareTimestamps = (a, b) =>
  DateTime.fromISO(a).toMillis() >= DateTime.fromISO(b).toMillis();

const timestampSorterAsc = (a, b) =>
  DateTime.fromISO(a.Timestamp).toMillis() -
  DateTime.fromISO(b.Timestamp).toMillis();

export const RESERVED_TAGS = ["low", "high", "red", "yellow", "green"];
export const isTagReserved = (t: string) =>
  RESERVED_TAGS.reduce((acc, curr) => acc || curr === t, false);
const crossCheckTags = (tags: string[], otherTags: string[]) =>
  otherTags.reduce((acc, curr) => acc || tags.includes(curr), false);
export const areTagsInvalid = (tags: string[]) =>
  crossCheckTags(tags, RESERVED_TAGS);

const mapLabelEventsToLabelsForPoint = (events: any[], pointId: string) => {
  const pointEvents = events.filter(event => event.pointId === pointId);
  const labelAddedEvents = pointEvents
    .filter(obj => (obj.type === "LabelAdded" || obj.type === "AssignedLabelAdded") && obj.labelType)
    .sort(timestampSorterAsc);

  const labelRemovedEvents = pointEvents
    .filter(obj => (obj.type === "LabelRemoved" || obj.type === "AssignedLabelRemoved") && obj.labelType)
    .sort(timestampSorterAsc);

  const activeLabels = labelAddedEvents.filter((labelAddedEvent) => {
    return labelRemovedEvents.findIndex(
      (labelRemovedEvent) =>
        labelRemovedEvent.labelType === labelAddedEvent.labelType &&
        labelRemovedEvent.tenantId === labelAddedEvent.tenantId &&
        compareTimestamps(labelRemovedEvent.Timestamp, labelAddedEvent.Timestamp)
    ) === -1;
  });
  return activeLabels.map((labelAddedEvent) => labelAddedEvent.labelType);
}

const isPointMultiPoint = (events: any[], pointId: string): boolean => {
  const pointEvents = events.filter(event => event.pointId === pointId);

  const enableEvents = pointEvents.filter(obj => obj.type === "MultiPointEnabled" || obj.type === "AssignedMultiPointEnabled").sort(timestampSorterAsc);

  if (enableEvents.length === 0) {
    return false;
  }

  const latestEnabledEvent = enableEvents[enableEvents.length - 1];
  const latestEnabledTimestamp = latestEnabledEvent.Timestamp;

  const disableEvents = pointEvents.filter(obj => obj.type === "MultiPointDisabled" || obj.type === "AssignedMultiPointDisabled").sort(timestampSorterAsc);

  const correspondingDisabledEvent = disableEvents.find(obj => compareTimestamps(obj.Timestamp, latestEnabledTimestamp));

  if (!correspondingDisabledEvent) {
    return true;
  }

  return false;
};

const mapTagEventsToTags = (events: any[]) => {
  const taggedEvents = events
    .filter((obj) => obj.type === "Tagged")
    .sort(timestampSorterAsc);
  const untaggedEvents = events
    .filter((obj) => obj.type === "Untagged")
    .sort(timestampSorterAsc);

  const tags = {};
  taggedEvents.forEach((taggedEvent) => {
    // Trying to find untagged event for that tag name that happened after the tagged event
    tags[taggedEvent.tag] =
      untaggedEvents.findIndex(
        (untaggedEvent) =>
          untaggedEvent.tag === taggedEvent.tag &&
          untaggedEvent.tenantId === taggedEvent.tenantId &&
          compareTimestamps(untaggedEvent.Timestamp, taggedEvent.Timestamp)
      ) === -1;
  });

  let checklistTags = Object.keys(tags).filter((tag) => tags[tag]);
  // if no priority tags, treat it as high
  if (!checklistTags.includes("high") && !checklistTags.includes("low"))
    checklistTags.push("high");
  // if no deviation handling tags, treat it as green
  if (!crossCheckTags(checklistTags, ["red", "yellow", "green"]))
    checklistTags.push("green");
  // Edge case in which the checklist includes both high and low tags
  // Take the most recent one
  if (checklistTags.includes("high") && checklistTags.includes("low")) {
    const latestPriorityTaggedEvent = taggedEvents
      .slice()
      .reverse()
      .find((obj) => obj.tag === "high" || obj.tag === "low");
    if (latestPriorityTaggedEvent?.tag === "high") {
      checklistTags = checklistTags.filter((tag) => tag !== "low");
    } else if (latestPriorityTaggedEvent?.tag === "low") {
      checklistTags = checklistTags.filter((tag) => tag !== "high");
    }
  }

  return checklistTags;
};

const hasOneOfTags = (tags: string[], checklistTags: string[]) =>
  tags.reduce((acc, curr) => acc || checklistTags.includes(curr), false);

export const mapTenantChecklists = (events: any, tags: string[] = null) => {
  const describedEvents = events.filter((obj) => obj.type === "Described");

  const checklists = describedEvents.map((e) => {
    return {
      name: e?.name,
      checklistId: e?.checklistId,
    };
  });

  return !tags || !tags.length
    ? checklists
    : checklists.filter((e) => {
      const checklistTags = mapTagEventsToTags(
        events.filter(
          (ev) =>
            ev.checklistId === e.checklistId &&
            (ev.type === "Tagged" || ev.type === "Untagged")
        )
      );
      return hasOneOfTags(tags, checklistTags);
    });
};

export const mapScheduledChecklistDashboard = (
  scheduleEvents,
  checklistEvents
) => {
  const checklists = mapAllChecklists(checklistEvents);

  let schedules = [];
  const hideDate = DateTime.local().startOf("day");
  const scheduleEventsToday = scheduleEvents.filter((item) => {
    return DateTime.fromISO(item.timestamp) > hideDate;
  });

  scheduleEventsToday.map((item) => {
    let checklistInfo = checklists.find(
      (e) => e.assignedId === item.assignedId
    );
    let status: ChecklistState;

    switch (item.type) {
      case "ScheduleDue":
        status = ChecklistState.OVERDUE;
        break;
      case "ScheduleVisible":
        status = ChecklistState.NEW;
        break;
      default:
        status = ChecklistState.IN_PROGRESS;
        break;
    }

    schedules.push({
      ...checklistInfo,
      sessionId: item.sessionId,
      dueTime: item.dueTime,
      startTime: item.startTime,
      status: status,
    });
  });
  return schedules;
};
export class AllwaysActiveSchedulesAggregat {
  constructor(
    public schedules: Array<any>,
    public checklistEvents: Array<any>,
    public tags: string[] = null
  ) {

  }
}
export const mapAllwaysActiveSchedules = (
  scheduleEvents: Array<any>,
  checklistEvents: Array<any>,
  tags: string[] = null
) => {
  const checklists = mapAllChecklists(checklistEvents, tags);
  let schedules = [];
  let assignmentGropudEvents = (scheduleEvents ?? []).reduce((acc, curr) => {
    if (!acc[curr.assignedId]) {
      acc[curr.assignedId] = [];
      schedules.push(curr.assignedId);
    }
    acc[curr.assignedId].push(curr);
    return acc;
  }, {});
  let allwaysActive = [];
  schedules.forEach((assignedId) => {
    let checklistInfo = checklists.find((e) => {
      return e.assignedId === assignedId;
    });
    if (!checklistInfo) return;
    let items = assignmentGropudEvents[assignedId];
    if (items.length <= 0) return;
    let lastEvent = items[0];
    if (!lastEvent.schedulingEnabled) return;
    allwaysActive.push({
      ...checklistInfo,
      ...lastEvent,
    });
  });
  return allwaysActive;
};

export const mapSchedules = (
  scheduleEvents: Array<any>,
  checklistEvents: Array<any>,
  tags: string[] = null
) => {
  const checklists = mapAllChecklists(checklistEvents, tags);

  let visible = [];
  let activated = [];
  let overDue = [];
  let inProgress = [];
  let hidden = scheduleEvents
    .filter((x) => x.type === "ScheduleHidden")
    .reduce((acc, x) => {
      if (acc[x.sessionId]) return acc;
      acc[x.sessionId] = true;
      return acc;
    }, {});

  let schedules = [];
  let sessionGropudEvents = scheduleEvents.reduce((acc, curr) => {
    if (hidden[curr.sessionId]) return acc;

    if (!acc[curr.sessionId]) {
      acc[curr.sessionId] = [];
      schedules.push(curr.sessionId);
    }
    acc[curr.sessionId].push(curr);
    return acc;
  }, {});
  schedules.forEach((sessionId) => {
    const visibleEvent = scheduleEvents.find(
      (e) => e.sessionId === sessionId && e.type === "ScheduleVisible"
    );
    // query window is 8 days. Partial sessions are ignored.
    if (!visibleEvent) return;
    let checklistInfo = checklists.find((e) => {
      return e.assignedId === visibleEvent?.assignedId;
    });
    // skip if there is no checklistInfo, this is due to tag filtering
    if (!checklistInfo) return;
    let items = sessionGropudEvents[sessionId].filter(
      (x) =>
        x.type === "SessionReopened" ||
        x.type == "SessionStarted" ||
        x.type === "ScheduleActivated" ||
        x.type === "ScheduleDue" ||
        x.type === "SessionStopped" ||
        x.type === "ScheduleVisible"
    );
    if (items.length <= 0) return;
    let lastEvent = items[0];
    let item = lastEvent;
    var other = null;
    if (lastEvent.type === "ScheduleVisible") {
      visible.push({
        ...checklistInfo,
        sessionId: item.sessionId,
        dueTime: item.dueTime,
        startTime: item.startTime,
      });
    } else if (
      lastEvent.type === "ScheduleActivated" &&
      !items.find(
        (x) => x.type === "SessionStopped" || x.type === "SessionStarted"
      )
    ) {
      activated.push({
        ...checklistInfo,
        sessionId: item.sessionId,
        dueTime: item.dueTime,
        readyTime: item.timestamp,
      });
    } else if (
      items.find((x) => x.type === "ScheduleDue") &&
      (!(other = items.find(
        (x) => x.type === "SessionStopped" || x.type === "SessionReopened"
      )) ||
        (other && other.type !== "SessionStopped"))
    ) {
      overDue.push({
        ...checklistInfo,
        sessionId: item.sessionId,
        dueTime: items.find((x) => x.type === "ScheduleDue").Timestamp,
        readyTime: visibleEvent.startTime,
      });
    } else {
      let inProgressEvents = items.filter(
        (x) =>
          x.type == "SessionStarted" ||
          x.type === "SessionStopped" ||
          x.type === "SessionReopened"
      );
      let startOtherEvent = inProgressEvents.find(
        (x) => x.type !== "SessionStarted"
      );
      let startEvent = inProgressEvents.find(
        (x) => x.type === "SessionStarted"
      );
      let isInProgress =
        startEvent &&
        (!startOtherEvent || startOtherEvent.type === "SessionReopened");
      if (isInProgress) {
        inProgress.push({
          ...checklistInfo,
          sessionId: item.sessionId,
          startedTime: item.timestamp,
          dueTime:
            items.find((x) => x.type === "ScheduleActivated")?.dueTime ??
            visibleEvent.dueTime,
        });
      }
    }
  });

  return {
    visible,
    activated,
    overDue,
    inProgress,
  };
};

export enum PointType {
  POINT,
  DIVIDER,
  MANUAL_ENTITY,
  BREAKAGE,
}

enum DisableResult {
  EnabledButNotDisabled,
  NotDisabled,
  DisabledButNotEnabled,
  DisabledAfterEnabled,
  EnabledAfterDisabled,
}

const DisableResultToBool: { [key in DisableResult]: boolean } = {
  [DisableResult.EnabledButNotDisabled]: false,
  [DisableResult.NotDisabled]: false,
  [DisableResult.DisabledButNotEnabled]: true,
  [DisableResult.DisabledAfterEnabled]: true,
  [DisableResult.EnabledAfterDisabled]: false,
};

const isDisabled = (
  latestDisableIndex: number,
  latestEnableIndex: number
): DisableResult => {
  if (latestEnableIndex !== -1 && latestDisableIndex === -1)
    return DisableResult.EnabledButNotDisabled;
  // no disable events
  if (latestDisableIndex === -1) return DisableResult.NotDisabled;
  // has a disable event but no enable events
  if (latestEnableIndex === -1) return DisableResult.DisabledButNotEnabled;
  // compare events order (< because the list is reversed!)
  return latestDisableIndex < latestEnableIndex
    ? DisableResult.DisabledAfterEnabled
    : DisableResult.EnabledAfterDisabled;
};

const isPointDisabled = (reversedPointEvents: any[]) => {
  const latestDisableEventIndex = reversedPointEvents.findIndex(
    (obj) => obj.type === "PointDisabled"
  );
  const latestEnableEventIndex = reversedPointEvents.findIndex(
    (obj) => obj.type === "PointEnabled"
  );
  const disabled = isDisabled(latestDisableEventIndex, latestEnableEventIndex);
  const disabledBool = DisableResultToBool[disabled];
  if (disabledBool) {
    const deleted = reversedPointEvents[latestDisableEventIndex].deleted;
    return {
      disabled,
      deleted
    }
  }
  return {
    disabled,
    deleted: false
  };
};

const isPointDisabledForSite = (reversedPointEvents, assignedId) => {
  const latestDisableEventIndex = reversedPointEvents.findIndex(
    (obj) => obj.type === "SitePointDisabled" && obj.assignedId === assignedId
  );
  const latestEnableEventIndex = reversedPointEvents.findIndex(
    (obj) => obj.type === "SitePointEnabled" && obj.assignedId === assignedId
  );
  const disabled = isDisabled(latestDisableEventIndex, latestEnableEventIndex);
  const disabledBool = DisableResultToBool[disabled];
  if (disabledBool) {
    const deleted = reversedPointEvents[latestDisableEventIndex].deleted;
    return {
      disabled,
      deleted
    }
  }
  return {
    disabled,
    deleted: false
  };
};

export const useGetSubPointEvents = (events: any[]) => {
  if (!events || events.length === 0) return [];
  return events.filter(
    (obj) =>
      obj.type === "SubPointAdded" ||
      obj.type === "SubPointRemoved"
  );
}

const existingSubPoints = (subPointEvents: any[]) => {
  const pointAddedEvents = subPointEvents
    .filter(obj => obj.type === "SubPointAdded")
    .sort(timestampSorterAsc);

  const pointRemovedEvents = subPointEvents
    .filter(obj => obj.type === "SubPointRemoved")
    .sort(timestampSorterAsc);

  const existingSubPointEvents = pointAddedEvents.filter((pointAddedEvent) => {
    return pointRemovedEvents.findIndex(
      (pointRemovedEvent) =>
        pointRemovedEvent.subPointId === pointAddedEvent.subPointId &&
        compareTimestamps(pointRemovedEvent.Timestamp, pointAddedEvent.Timestamp)
    ) === -1;
  });
  return existingSubPointEvents;
};


export const useUpdateChecklistWithEvents = (events: any[], checklist: any, assignedId: string) => {

  const labelingEvents = events.filter((e) =>
    (e.type === "LabelAdded" || e.type === "LabelRemoved" || e.type === "AssignedLabelAdded" || e.type === "AssignedLabelRemoved"));


  const mapPoint = (pointId: string, event: any, subPointId?: string) => {
    const currentPointEvents = events
      .filter((obj) => obj.pointId === pointId)
      .slice()
      .reverse();

    if (event.type === "DividerAdded") {
      return {
        id: pointId,
        type: PointType.DIVIDER,
      };
    } else {
      const latestDescribedEvent = currentPointEvents.find(
        (obj) => obj.type === "PointDescribed"
      );
      const latestMediaPointEvent = currentPointEvents.find(
        (obj) => obj.type === "AddMediaPoint"
      );
      const latestSiteMediaPointEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SiteAddMediaPoint" && obj.assignedId === assignedId
      );
      const latestExternalLinkAddedPointEvent = currentPointEvents.find(
        (obj) => obj.type === "PointExternalLinkAdded"
      );
      const latestExternalLinkEnabledOrDisabledEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "PointExternalLinkEnabled" ||
          obj.type === "PointExternalLinkDisabled"
      );

      const latestSiteNamedEvent = currentPointEvents.find(
        (obj) => obj.type === "SitePointNamed" && obj.assignedId === assignedId
      );
      const latestSiteDescribedEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SitePointDescribed" && obj.assignedId === assignedId
      );
      const latestSiteDetailsEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SitePointDetails" && obj.assignedId === assignedId
      );

      const isMultiPoint = isPointMultiPoint(
        currentPointEvents,
        pointId
      );

      const { disabled: pointDisabled, deleted: pointDeleted } = isPointDisabled(currentPointEvents);
      const { disabled: sitePointDisabled, deleted: sitePointDeleted } = isPointDisabledForSite(
        currentPointEvents,
        assignedId
      );

      const eventWithDifferentChecklistId = currentPointEvents.find(
        (obj) => obj.checklistId !== checklist.checklistId
      );

      let point: any = {
        id: subPointId ?? pointId,
        parentPointId: pointId,
        type: PointType.POINT,
        isSiteSpecific: (event.type === "SitePointAdded" || event.type === "AssignedSubPointAdded"),
        name: latestDescribedEvent?.name,
        description: latestDescribedEvent?.description,
        siteName: latestSiteNamedEvent?.name,
        siteDescription: latestSiteDescribedEvent?.description,
        siteDetails: latestSiteDetailsEvent?.details,
        enabled: !DisableResultToBool[pointDisabled],
        deleted: pointDeleted,
        tenantId: event.tenantId,
        externalLinkEnabled: !(
          latestExternalLinkEnabledOrDisabledEvent?.type ===
          "PointExternalLinkDisabled"
        ),
        externalLink: !latestExternalLinkAddedPointEvent
          ? null
          : {
            title: latestExternalLinkAddedPointEvent?.title,
            url: latestExternalLinkAddedPointEvent?.url,
            iconUrl: latestExternalLinkAddedPointEvent?.iconUrl,
          },
        siteEnabled:
          sitePointDisabled === DisableResult.NotDisabled
            ? !DisableResultToBool[pointDisabled]
            : !DisableResultToBool[sitePointDisabled],
        deletedForSite: sitePointDeleted,
        image: latestMediaPointEvent?.imagePath
          ? `${FILEUPLOAD_URL}/${latestMediaPointEvent.imagePath}`
          : latestDescribedEvent?.imagePath &&
          `${FILEUPLOAD_URL}/${latestDescribedEvent.imagePath}`,
        siteImage: latestSiteMediaPointEvent?.imagePath
          ? `${FILEUPLOAD_URL}/${latestSiteMediaPointEvent.imagePath}`
          : "",
        isPointIncluded: !!eventWithDifferentChecklistId,
        includedFromChecklistId: eventWithDifferentChecklistId?.checklistId,
        labels: mapLabelEventsToLabelsForPoint(labelingEvents, pointId),
        isMultiPoint: isMultiPoint,
        isSubPoint: (event.type === "SubPointAdded" || event.type === "AssignedSubPointAdded")
      };
      return point;
    }
  }
  events.forEach(event => {
    var point = mapPoint(event.pointId, event, event.subPointId);
    checklist.sitePoints = checklist.sitePoints.concat(point);
  });

}


// Similar to mapAllChecklists. This is used for one checklist and more detailed
export const mapChecklist = (events: any, assignedId: string) => {
  const assignedIdExists = !!events.find(
    (obj) => obj.type === "AssignedToSite" && obj.assignedId === assignedId
  );

  const schedule = events
    .slice()
    .reverse()
    .find(
      (obj) => ((obj.type === "ScheduleCreated" || obj.type === "AllwaysScheduleCreated") && obj.assignedId === assignedId)
    );
  const checklistId = events.find((obj) => obj.type === "Created").checklistId;

  const labelingEvents = events.filter((e) =>
    (e.type === "LabelAdded" || e.type === "LabelRemoved" || e.type === "AssignedLabelAdded" || e.type === "AssignedLabelRemoved")
  );


  const latestDescribedEvent = events
    .slice()
    .reverse()
    .find((e) => e.type === "Described");
  const latestSiteDescribedEvent = events
    .slice()
    .reverse()
    .find((e) => e.type === "SiteDescribed" && e.assignedId === assignedId);

  const checklistTags = mapTagEventsToTags(events);
  const latestMediaChecklistEvent = events
    .slice()
    .reverse()
    .find((obj) => obj.type === "AddMediaChecklist");

  //list of all add and disable media events
  const allAddMediaChecklistEvents = events.filter(
    (obj) => obj.type === "AddMediaChecklist"
  );

  const allDisableMediaChecklistEvents = events.filter(
    (obj) => obj.type === "DisableMediaChecklist"
  );

  const allMediaChecklistEvents = allAddMediaChecklistEvents.filter(
    (add) =>
      allDisableMediaChecklistEvents.filter(
        (dis) => dis.imagePath === add.imagePath
      ).length == 0
  );

  const allMediaMarkdownEvents = events.filter(
    (obj) => obj.type === "AddMediaMarkdown"
  );

  let checklistPointAddedEvents = events.filter(
    (obj) =>
      obj.type === "PointAdded" ||
      obj.type === "DividerAdded" ||
      (obj.type === "SitePointAdded" && obj.assignedId === assignedId)
  );

  const checkListSubPointEvents = events.filter(
    (obj) =>
    (obj.type === "SubPointAdded" ||
      obj.type === "AssignedSubPointAdded" ||
      obj.type === "SubPointRemoved" ||
      obj.type === "AssignedSubPointRemoved")
  )

  const sortEvents = events.filter(
    (obj) =>
      obj.type === "PointMovedAfter" ||
      (obj.type === "SitePointMovedAfter" && obj.assignedId === assignedId)
  );

  const mapPoint = (pointId: string, event: any, subPointId?: string) => {
    const currentPointEvents = events
      .filter((obj) => obj.pointId === pointId)
      .slice()
      .reverse();

    if (event.type === "DividerAdded") {
      return {
        id: pointId,
        type: PointType.DIVIDER,
      };
    } else {
      const latestDescribedEvent = currentPointEvents.find(
        (obj) => obj.type === "PointDescribed"
      );
      const latestMediaPointEvent = currentPointEvents.find(
        (obj) => obj.type === "AddMediaPoint"
      );
      const latestSiteMediaPointEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SiteAddMediaPoint" && obj.assignedId === assignedId
      );
      const latestExternalLinkAddedPointEvent = currentPointEvents.find(
        (obj) => obj.type === "PointExternalLinkAdded"
      );
      const latestExternalLinkEnabledOrDisabledEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "PointExternalLinkEnabled" ||
          obj.type === "PointExternalLinkDisabled"
      );

      const latestSiteNamedEvent = currentPointEvents.find(
        (obj) => obj.type === "SitePointNamed" && obj.assignedId === assignedId
      );
      const latestSiteDescribedEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SitePointDescribed" && obj.assignedId === assignedId
      );
      const latestSiteDetailsEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "SitePointDetails" && obj.assignedId === assignedId
      );

      const isMultiPoint = isPointMultiPoint(
        currentPointEvents.sort(e => e.type === "MultiPointEnabled" || e.type === "MultiPointDisabled" || e.type === "AssignedMultiPointEnabled" || e.type === "AssignedMultiPointDisabled"),
        pointId
      );


      const { disabled: pointDisabled, deleted: pointDeleted } = isPointDisabled(currentPointEvents);
      const { disabled: sitePointDisabled, deleted: sitePointDeleted } = isPointDisabledForSite(
        currentPointEvents,
        assignedId
      );

      const eventWithDifferentChecklistId = currentPointEvents.find(
        (obj) => obj.checklistId !== checklistId
      );

      let point: any = {
        id: subPointId ?? pointId,
        parentPointId: pointId,
        type: PointType.POINT,
        isSiteSpecific: (event.type === "SitePointAdded" || event.type === "AssignedSubPointAdded"),
        name: latestDescribedEvent?.name,
        description: latestDescribedEvent?.description,
        siteName: latestSiteNamedEvent?.name,
        siteDescription: latestSiteDescribedEvent?.description,
        siteDetails: latestSiteDetailsEvent?.details,
        enabled: !DisableResultToBool[pointDisabled],
        deleted: pointDeleted,
        tenantId: event.tenantId,
        externalLinkEnabled: !(
          latestExternalLinkEnabledOrDisabledEvent?.type ===
          "PointExternalLinkDisabled"
        ),
        externalLink: !latestExternalLinkAddedPointEvent
          ? null
          : {
            title: latestExternalLinkAddedPointEvent?.title,
            url: latestExternalLinkAddedPointEvent?.url,
            iconUrl: latestExternalLinkAddedPointEvent?.iconUrl,
          },
        siteEnabled:
          sitePointDisabled === DisableResult.NotDisabled
            ? !DisableResultToBool[pointDisabled]
            : !DisableResultToBool[sitePointDisabled],
        deletedForSite: sitePointDeleted,
        image: latestMediaPointEvent?.imagePath
          ? `${FILEUPLOAD_URL}/${latestMediaPointEvent.imagePath}`
          : latestDescribedEvent?.imagePath &&
          `${FILEUPLOAD_URL}/${latestDescribedEvent.imagePath}`,
        siteImage: latestSiteMediaPointEvent?.imagePath
          ? `${FILEUPLOAD_URL}/${latestSiteMediaPointEvent.imagePath}`
          : "",
        isPointIncluded: !!eventWithDifferentChecklistId,
        includedFromChecklistId: eventWithDifferentChecklistId?.checklistId,
        labels: mapLabelEventsToLabelsForPoint(labelingEvents, pointId),
        isMultiPoint: isMultiPoint,
        isSubPoint: (event.type === "SubPointAdded" || event.type === "AssignedSubPointAdded")
      };

      const latestManualEntityEvent = currentPointEvents.find(
        (obj) =>
          obj.type === "PointManualEntityTagged" &&
          obj.assignedId === assignedId
      );

      if (latestManualEntityEvent) {
        point = {
          ...point,
          type: PointType.MANUAL_ENTITY,
          entityId: latestManualEntityEvent.entityId,
        };
      }

      const breakagePointEvent = currentPointEvents.find(
        (obj) => obj.type === "PointBreakage"
      );

      if (breakagePointEvent) {
        point = {
          ...point,
          type: PointType.BREAKAGE,
          unit: breakagePointEvent.unit,
        };
      }

      return point;
    }
  }


  checklistPointAddedEvents = checklistPointAddedEvents.concat(existingSubPoints(checkListSubPointEvents));

  let checklistPoints = checklistPointAddedEvents.map((item) => {
    if (item.type === "SubPointAdded" || item.type === "AssignedSubPointAdded") {
      return mapPoint(item.pointId, item, item.subPointId);
    }
    else return mapPoint(item.pointId, item);
  });

  sortEvents.forEach((item) => {
    const refPointIndex = checklistPoints.indexOf(
      checklistPoints.find((el) => el.id === item.referencePoint)
    );

    const setAfterIndex = checklistPoints.indexOf(
      checklistPoints.find((el) => el.id === item.setAfter)
    );

    let movedPoint = checklistPoints.splice(refPointIndex, 1)[0];
    checklistPoints.splice(
      setAfterIndex,
      0,
      movedPoint
    );
  });

  return {
    assignedId: assignedId,
    checklistId: checklistId,
    tenantId: latestDescribedEvent?.tenantId,
    name: latestDescribedEvent?.name,
    description: latestDescribedEvent?.description,
    siteName: latestSiteDescribedEvent?.name,
    siteDescription: latestSiteDescribedEvent?.description,
    tags: checklistTags,
    sitePoints: checklistPoints.filter((point) => point.siteEnabled && !point.deleted && !point.deletedForSite),
    checklistPoints: checklistPoints.filter((point) => !point.deleted && !point.deletedForSite),
    points: checklistPoints.filter((point) => point.enabled && !point.deleted && !point.deletedForSite),
    templatePoints: checklistPoints.filter((point) => !point.isSiteSpecific && !point.deleted && !point.deletedForSite),
    allPoints: checklistPoints.filter((point) => !point.deleted && !point.deletedForSite),
    isScheduleDefined: !!schedule,
    schedule: {
      visibleFrom: schedule?.visibleFrom || "",
      todoFrom: schedule?.todoFrom || "",
      dueFrom: schedule?.dueFrom || "",
      hideFrom: schedule?.hideFrom || "",
      schedulingEnabled: schedule?.schedulingEnabled,
      isAllwaysSchedule: schedule?.type === "AllwaysScheduleCreated" && schedule?.schedulingEnabled,
    },
    image: latestMediaChecklistEvent?.imagePath?.startsWith("https")
      ? latestMediaChecklistEvent.imagePath
      : latestMediaChecklistEvent?.imagePath &&
      `${FILEUPLOAD_URL}/${latestMediaChecklistEvent.imagePath}`,
    images: allMediaChecklistEvents.map((img) => {
      return `${FILEUPLOAD_URL}/${img.imagePath}`;
    }),
    disabledImages: allDisableMediaChecklistEvents.map((img) => {
      return `${FILEUPLOAD_URL}/${img.imagePath}`;
    }),
    markdownImages: allMediaMarkdownEvents.map((img) => {
      return `${FILEUPLOAD_URL}/${img.imagePath}`;
    }),
  };
};

export const mapSessions = (events: Array<any>, sessionsWithDeviations: string[] = []) => {
  const sessionEvents = events.reduce((acc, curr) => {
    if (!acc[curr.sessionId]) {
      acc[curr.sessionId] = [];
    }
    acc[curr.sessionId].push(curr);
    return acc;
  }, {});
  // const startEvents = events
  //   .filter((value) => value.type === "SessionStarted")
  //   .sort(function (x, y) {
  //     let d1: any = new Date(x.Timestamp);
  //     let d2: any = new Date(y.Timestamp);
  //     return d2 - d1;
  //   });

  // const stopEvents = events.filter((value) => value.type === "SessionStopped");

  const sessions: Array<any> = Object.keys(sessionEvents).map((key) => {
    const session = sessionEvents[key];

    var stopStatEvents = sessionsWithDeviations.includes(key) ? session : session.filter(
      (x) => ["SessionStarted", "SessionReopened", "SessionStopped"].indexOf(x.type) >= 0
    );

    const { event: lastEvent } = getFirstEvent(stopStatEvents);
    const { event: lastHiddenEvent } = getFirstEvent(session, "ScheduleHidden");
    var now = DateTime.now().toUTC();
    var lastEventTime =  !!lastHiddenEvent && DateTime.fromISO(lastHiddenEvent.Timestamp).toUTC();
    let obj = {
      id: key,
      status: lastEvent.type === "SessionStopped" ? "FINISHED" : "IN_PROGRESS",
      isHidden: !!lastHiddenEvent && lastEventTime < now,
      timestamp: lastEvent.Timestamp,
      username: lastEvent.username,
      assignedId: lastEvent?.assignedId,
      sessionId: lastEvent?.sessionId,
    };
    return obj;
  });
  return sessions;
};

export const mapSessionEvents = (events: Array<any>, checklist: any) => {
  const tickedPoints: Array<string> = [];
  const measurements: any = {};
  let breakages: any = {};
  let labels: any = {};
  checklist.points.map((item) => {
    const pointHandleEvents = events.filter(
      (value) => value.pointId === item.id
    );

    const checkEvents = pointHandleEvents.filter(
      (e) => e.type === "PointChecked" || e.type === "PointUnchecked"
    );

    checkEvents[checkEvents.length - 1]?.type === "PointChecked" &&
      tickedPoints.push(item.id);

    const lastMeasurement = pointHandleEvents
      .slice()
      .reverse()
      .find((e) => e.type === "PointMeasurementTyped");
    if (lastMeasurement) {
      measurements[item.id] = {
        value: lastMeasurement.measurement,
      };
    }

    const lastBreakage = pointHandleEvents
      .slice()
      .reverse()
      .find((e) => e.type === "PointBreakageTyped");

    const latestLabelEventsForSession: Record<string, any> = {};
    const sessionLabelEvents = pointHandleEvents.filter(e => e.type == "Labeled");
    if (sessionLabelEvents) {
      sessionLabelEvents.forEach((event) => {
        if (!latestLabelEventsForSession[event.labelType] || event.Timestamp > latestLabelEventsForSession[event.labelType].Timestamp) {
          latestLabelEventsForSession[event.labelType] = event;
        }
      })
      labels = Object.values(latestLabelEventsForSession);
    }

    if (lastBreakage) {
      breakages[item.id] = {
        value: lastBreakage.amount,
      };
    }
  });

  return {
    tickedPoints,
    measurements,
    breakages,
    labels
  };
};

type Session = {
  id: string;
  name: string;
  checked: boolean;
  deviations: Array<any>;
  measurement?: number;
  breakage?: any;
  labels: any;
  signatureId?: string;
};



const mapSessionPoint = (pointId: string, pointName: string, events: any[]) => {
  const obj: Session = {
    id: pointId,
    name: pointName,
    checked: false,
    deviations: [],
    signatureId: null,
    labels: null
  };
  const pointEvents = events.filter((value) => {
    return value.pointId === pointId;
  });

  const checkEvents = pointEvents.filter(
    (value) =>
      value.type === "PointChecked" || value.type === "PointUnchecked"
  );

  const lastCheckEvent = checkEvents[checkEvents.length - 1];
  if (lastCheckEvent?.type === "PointChecked") {
    obj.checked = true;
    obj.signatureId = lastCheckEvent.signatureId ?? lastCheckEvent.userId;
  }

  const deviationEvents = pointEvents.filter(
    (value) => value.type === "DeviationReported"
  );
  const measurement = pointEvents
    .reverse()
    .find((e) => e.type === "PointMeasurementTyped");

  if (measurement) {
    obj.measurement = measurement.measurement;
  }

  const breakage = pointEvents
    .reverse()
    .find((e) => e.type === "PointBreakageTyped");

  if (breakage) {
    obj.breakage = {
      value: breakage.amount,
      unit: breakage.unit,
    };
  }

  const latestLabelEventsForSession: Record<string, any> = {};
  const sessionLabelEvents = pointEvents.filter(e => e.type == "Labeled");
  if (sessionLabelEvents) {
    sessionLabelEvents.forEach((event) => {
      if (!latestLabelEventsForSession[event.labelType] || event.Timestamp > latestLabelEventsForSession[event.labelType].Timestamp) {
        latestLabelEventsForSession[event.labelType] = event;
      }
    })
    obj.labels = Object.values(latestLabelEventsForSession);
  }

  deviationEvents &&
    deviationEvents.map((item) => {
      const images = item.images?.map((img) => {
        return `${FILEUPLOAD_URL}/checklist/${img}`;
      });
      const handledDeviation = pointEvents.find(
        (e) => e.type === "DeviationHandled" && e.deviationId === item.id
      );

      obj.deviations.push({
        id: item.id,
        comment: item.comment,
        images: images,
        handled: Boolean(handledDeviation),
      });
    });

  return obj;
}

export const mapSession = (events: Array<any>, checklist: any) => {
  const session: Array<Session> = [];

  const subPointEvents = useGetSubPointEvents(events);
  const activeSubPoints = existingSubPoints(subPointEvents);

  activeSubPoints.map((item) => {
    const parentPoint = checklist.points.find((point) => point.id === item.pointId);
    const pointName = parentPoint ? parentPoint.name : '';
    session.push(mapSessionPoint(item.subPointId, pointName, events));
  });

  checklist.points.map((item) => {
    session.push(mapSessionPoint(item.id, item.name, events));
  });
  return session;
};

//6bae4ead-6167-4166-af70-f1eb174d1cfc
export const useSiteChecklists = (siteId: string) =>
  useAxios(`${CREATION_URL}/site/${siteId}`);

export const useSiteChecklistsWithMedia = (siteId: string) =>
  useAxios(`${CREATION_URL}/site/${siteId}/with-media`);

export const useTenantChecklists = (tenantId: string) =>
  axios.get(`${CREATION_URL}/tenant/${tenantId}`);

export const useChecklist = (checklistId: string) => {
  return useAxios(`${CREATION_URL}/${checklistId}`);
};
export const useAllwaysScheduled = (siteId: string) => {
  return useAxios(`${CREATION_URL}/alawaysactiveschedules/${siteId}`);
};
export const fetchChecklists = (checklistIds: string[]) => {
  let ids: string = "";
  checklistIds.forEach((element, key) => {
    ids = ids.concat(
      `checklistIds=${element}${checklistIds.length - 1 > key ? "&" : ""}`
    );
  });
  return axios
    .get(`${CREATION_URL}/checklists?${ids}`)
    .then((resp) => resp.data);
};

export const useGetAssignedChecklist = (
  assignedId: string,
  stateTime?: DateTime
) => {
  if (stateTime != null) {
    return useAxios(
      `${CREATION_URL}/assigned/${assignedId}?stateTime=${stateTime.toUTC().toISO()}`
    );
  }
  return useAxios(`${CREATION_URL}/assigned/${assignedId}`);
};

export const useGetAssignedChecklistComposite = (
  assignedId: string,
  stateTime?: DateTime
) => {
  if (stateTime != null) {
    return useAxios(
      `${CREATION_URL}/assigned/${assignedId}/composite?stateTime=${stateTime.toUTC().toISO()}`,
      { useCache: false }
    );
  }
  return useAxios(`${CREATION_URL}/assigned/${assignedId}/composite`, {
    useCache: false,
  });
};

type StartChecklistType = {
  checklistId: string;
  tenantId: string;
  assignedId: string;
  sessionId: string;
  userId: string;
  siteId: string;
  username: string;
  signatureId: string;
};

export const useStartChecklist = (data: StartChecklistType) => {
  const promise = axios.post(`${CHECKLIST_HANDELING_URL}/start`, {
    ...data,
    type: "SessionStarted",
  });

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

type StopChecklistType = {
  checklistId: string;
  tenantId: string;
  assignedId: string;
  sessionId: string;
  userId: string;
  siteId: string;
  signatureId: string;
};

export const useStopChecklist = async (data: StopChecklistType) => {
  await axios.post(`${CHECKLIST_HANDELING_URL}/stop`, {
    ...data,
    type: "SessionStopped",
  });
};

export const useCheckPoint = async (data) => {
  await axios.post(`${CHECKLIST_HANDELING_URL}/checkPoint`, {
    ...data,
    type: "PointChecked",
  });
};

export const useUncheckPoint = async (data) => {
  await axios.post(`${CHECKLIST_HANDELING_URL}/uncheckPoint`, {
    ...data,
    type: "PointUnchecked",
  });
};

type ReportDeviation = {
  checklistId: string;
  tenantId: string;
  sessionId: string;
  assignedId: string;
  pointId: string;
  comment: string;
  img: Array<string>;
  userId: string;
  signatureId: string;
};

type ReportTemperatureDeviation = ReportDeviation & { value: string };

export const useReportDeviation = async (data: ReportDeviation) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/reportDeviation`, {
      ...data,
      type: "DeviationReported",
    })
    .then((result) => {
      return result;
    });
};

export const useReportTemperatureDeviation = async (
  data: ReportTemperatureDeviation
) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/reportTemperatureDeviation`, {
      ...data,
      type: "TemperatureDeviationReported",
    })
    .then((result) => {
      return result;
    });
};

export const useReportChecklistDeviation = async (data: any) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/reportChecklistDeviation`, {
      ...data,
      type: "ChecklistDeviationReported",
    })
    .then((result) => {
      return result;
    });
};

export const useChecklistComment = async (data: any) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/checklistComment`, {
      ...data,
      type: "ChecklistComment",
    })
    .then((result) => {
      return result;
    });
};

export const useImageUpload = async (
  data: any,
  id: string,
  extension: SupportedMediaFormatsType,
  blob: string = BASE_CHECKLIST_MEDIA_PATH,
  onUploadProgress: (percentage: number) => any = () => { }
) => {
  await axios.put(`${FILEUPLOAD_URL}/${blob}/${id}${extension}`, data, {
    headers: {
      "Content-type": "application/json; charset=utf-8",
    },
    onUploadProgress: (e) => {
      onUploadProgress(Math.round((e.loaded / e.total) * 100));
    },
  });
};

export const useGetOpenSessions = (assignedId: string) => {
  return useAxios(`${CHECKLIST_HANDELING_URL}/openSessions/${assignedId}`);
};

export const useGetSessions = (assignedId: string,) => {
  return useAxios(`${CHECKLIST_HANDELING_URL}/allSessions/${assignedId}`);
};

export const useGetSiteSessions = (siteId: string) => {
  return useAxios(`${CHECKLIST_HANDELING_URL}/siteSessions/${siteId}`);
};

export const useGetSession = (id) => {
  return axios
    .get(`${CHECKLIST_HANDELING_URL}/session/${id}`)
    .then((response) => response.data);
};

export const useSession = (id) => {
  return useAxios(`${CHECKLIST_HANDELING_URL}/session/${id}`);
};

export const useSchedule = (siteId: string) => {
  return useAxios(`${CHECKLIST_HANDELING_URL}/schedule/${siteId}`);
};

export const useSiteDeviations = (assignedIds: Array<string>) => {
  let ids: string = "";
  assignedIds.forEach((element, key) => {
    ids = ids.concat(
      `ids=${element}${assignedIds.length - 1 > key ? "&" : ""}`
    );
  });

  return useAxios(`${CHECKLIST_HANDELING_URL}/assigned/deviations?${ids}`);
};

export const useSiteDeviationsFromTo = (
  siteId: string,
  from: string,
  to: string
) => {
  return useAxios(
    `${CHECKLIST_HANDELING_URL}/site/deviations/${siteId}?from=${from}&to=${to}`
  );
};

export const useAssignedIdsDeviationsFromTo = (
  assignedIds: Array<string>,
  from: string
) => {
  let ids: string = "";
  assignedIds.forEach((element, key) => {
    ids = ids.concat(
      `ids=${element}${assignedIds.length - 1 > key ? "&" : ""}`
    );
  });
  return axios.get(
    `${CHECKLIST_HANDELING_URL}/assigned/deviations?${ids}&from=${from}`
  );
};

export const mapSiteDeviations = (events) => {
  const openDeviations = [];

  const deviationsReported = events.filter(
    (e) => e.type === "DeviationReported"
  );
  deviationsReported.forEach((deviation) => {
    const handled = events.find(
      (e) => e.type === "DeviationHandled" && e.deviationId === deviation.id
    );

    if (!handled) {
      openDeviations.push(deviation);
    }
  });

  return openDeviations;
};

export const useTakeActionForDeviation = async (data) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/takeActionForDeviation`, {
      ...data,
      type: "DeviationActionTaken",
    })
    .then((result) => {
      return result.data;
    });
};

type HandleDeviation = {
  // checklistId: string;
  tenantId: string;
  sessionId: string;
  assignedId: string;
  pointId: string;
  userId: string;
  comment: string;
  deviationId: string;
  signatureId: string;
};

export const useHandleDeviation = async (data: HandleDeviation) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/handleDeviation`, {
      ...data,
      type: "DeviationHandled",
    })
    .then((result) => {
      return result;
    });
};

type HandleChecklistDeviation = {
  // checklistId: string;
  tenantId: string;
  sessionId: string;
  assignedId: string;
  userId: string;
  comment: string;
  deviationId: string;
  signatureId: string;
};

export const useHandleChecklistDeviation = async (
  data: HandleChecklistDeviation
) => {
  await axios
    .post(`${CHECKLIST_HANDELING_URL}/handleChecklistDeviation`, {
      ...data,
      type: "ChecklistDeviationHandled",
    })
    .then((result) => {
      return result;
    });
};

export const useGetCompletedChecklists = (
  siteId: string,
  from: string,
  to: string
) => {
  return useAxios(
    `${CHECKLIST_HANDELING_URL}/site/completed/${siteId}?from=${from}&to=${to}`
  );
};

export const useReopenSession = async (data) => {
  await axios.post(`${CHECKLIST_HANDELING_URL}/reopenSession`, {
    ...data,
    type: "SessionReopened",
  });
};
function getLastEvent(session: Array<any>, eventType: string = "") {
  const events =
    eventType != ""
      ? session.filter((value) => value.type === eventType)
      : session;
  const event = events.length > 0 && events[events.length - 1];
  return { events, event };
}
function getFirstEvent(session: Array<any>, eventType: string = "") {
  const events =
    eventType != ""
      ? session.filter((value) => value.type === eventType)
      : session;
  const event = events.length > 0 && events[0];
  return { events, event };
}
export const useAddChecklistPoint = (data: any) =>
  useChecklistPost("/addpoint", data);

export const useAddChecklistPointToSite = (data: any) =>
  useChecklistPost("/addPointSite", data);

export const useNamePointSite = (data: any) =>
  useChecklistPost("/namePointSite", data);

export const useAddMediaToPoint = (data: any) =>
  useChecklistPost("/addMediaPoint", data);

export const useSiteAddMediaToPoint = (data: any) =>
  useChecklistPost("/siteAddMediaPoint", data);

export const useLabelAddedToPoint = (data: any) =>
  useChecklistPost("/addLabelToPoint", data);

export const useLabelRemovedFromPoint = (data: any) =>
  useChecklistPost("/removeLabelFromPoint", data);

export const useAssignedLabelAddedToPoint = (data: any) =>
  useChecklistPost("/addLabelToAssignedPoint", data);

export const useAssignedLabelRemovedFromPoint = (data: any) =>
  useChecklistPost("/removeLabelFromAssignedPoint", data);

export const useMultiPointEnabled = (data: any) =>
  useChecklistPost("/enableMultiPoint", {
    ...data,
    type: "MultiPointEnabled"
  });

export const useMultiPointDisabled = (data: any) =>
  useChecklistPost("/disableMultiPoint", {
    ...data,
    type: "MultiPointDisabled"
  });

export const useAssignedMultiPointEnabled = (data: any) =>
  useChecklistPost("/assignedEnableMultiPoint", {
    ...data,
    type: "AssignedMultiPointEnabled"
  });

export const useAssignedMultiPointDisabled = (data: any) =>
  useChecklistPost("/assignedDisableMultiPoint", {
    ...data,
    type: "AssignedMultiPointDisabled"
  });

export const useAddSubPoint = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/addSubPoint`, {
    ...data,
    type: "SubPointAdded",
  });
}

export const useRemoveSubPoint = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/removeSubPoint`, {
    ...data,
    type: "SubPointRemoved",
  });
}

export const useAddPointDescription = (data: any) =>
  useChecklistPost("/describepoint", data);

export const useAddPointExternalLink = (data: any) =>
  useChecklistPost("/addPointExternalLink", data);

export const useEnablePointExternalLink = (data: any) =>
  useChecklistPost("/enablePointExternalLink", data);

export const useDisablePointExternalLink = (data: any) =>
  useChecklistPost("/disablePointExternalLink", data);

export const useAddSitePointDescription = (data: any) =>
  useChecklistPost("/siteDescribePoint", data);

export const useAddSitePointDetails = (data: any) =>
  useChecklistPost("/siteDetailsPoint", data);

export const useTagPointWithEntity = (data: any) =>
  useChecklistPost("/tagPointManualEntity", data);

export const useMovePointAfter = (data: any) =>
  useChecklistPost("/moveAfter", data);

export const useSiteMovePointAfter = (data: any) =>
  useChecklistPost("/siteMoveAfter", data);

export const useDisablePoint = (data: any) =>
  useChecklistPost("/disablePoint", data);

export const useEnablePoint = (data: any) =>
  useChecklistPost("/enablePoint", data);

export const useSiteDisablePoint = (data: any) =>
  useChecklistPost("/siteDisablePoint", data);

export const useSiteEnablePoint = (data: any) =>
  useChecklistPost("/siteEnablePoint", data);

export const useAddBreakageToPoint = (data: any) =>
  useChecklistPost("/breakagePoint", data);

export const useAddSchedule = (data: any) =>
  useChecklistPost("/schedule", data);

export const useAddAllwaysScheduled = (data: any) =>
  useChecklistPost("/alawaysactiveschedules", data);

export const useAssociateChecklistPoints = (data: any) =>
  useChecklistPost("/includeChecklistPoints", data);

export const useChecklistPost = (path: string, data: any) => {
  const promise = axios.post(`${CREATION_URL}${path}`, data);

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const useGetChecklistTags = (tenantId: string) => {
  return useAxios(`${CREATION_URL}/tags/tenant/${tenantId}`);
};

export const useGetPointEvents = (pointId) => {
  return useAxios(`${CREATION_URL}/point/${pointId}`);
};

export const useGetAllPointEvents = (siteId) => {
  return useAxios(`${CREATION_URL}/points/${siteId}`);
};

export const useCreateChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/create/`, data);
};

export const useAddMediaToChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/addMedia/`, data);
};
export const useDisableMediaToChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/disableMedia/`, data);
};

export const useAddMediaToChecklistMarkdown = (data: any) => {
  return axios.post(`${CREATION_URL}/addMediaMarkdown/`, data);
};

export const useDescribeChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/describe/`, data);
};

export const useSiteDescribeChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/describeForSite/`, data);
};

export const useTagChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/tag/`, data);
};

export const useUntagChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/untag/`, data);
};

export const useAssignChecklist = (data: any) => {
  return axios.post(`${CREATION_URL}/assignToSite/`, data);
};
export const useRemoveAssignChecklist = (data: any) => {
  console.log("data", data);
  return axios.post(`${CREATION_URL}/command/removeAssignmentCommand`, data);
};
export const useMeasurementType = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/measurementTyped`, {
    ...data,
    type: "PointMeasurementTyped",
  });
};

export const useBreakageType = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/breakageTyped`, {
    ...data,
    type: "PointBreakageTyped",
  });
};

export const usePointLabeled = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/pointLabeled`, {
    ...data,
    type: "Labeled",
  });
}

export const getLabelTypes = () => {
  return axios.get(`${CREATION_URL}/labelTypes`).then((res) => res.data)
}

export const useGetLabelValues = (labelType: string) => {
  return axios.get(`${CREATION_URL}/labelValuesForType?labelType=${encodeURIComponent(labelType)}`)
    .then((res) => res.data);
}

export const startRoutineImmediately = async (checklist: any) => {
  const timeToComplete = 10;
  const timeStamp = DateTime.now();
  const visibleTime = timeStamp.plus({ seconds: 1 });
  const startTime = visibleTime.plus({ seconds: 1 });
  const dueTime = startTime.plus({ minutes: timeToComplete });
  const hideTime = dueTime.plus({ minutes: timeToComplete });
  const sessionId = Guid.newGuid().toString();

  var manualScheduleCommand = {
    visibleTime,
    startTime,
    dueTime,
    hideTime,
    tenantId: checklist.tenantId,
    sessionId,
    assignedId: checklist.assignedId,
    type: "ScheduleManual",
    siteId: checklist.siteId,
    timeStamp: timeStamp,
  };
  var result = await usePostManualRoutine(manualScheduleCommand);
  return { result, manualScheduleCommand }
};

export const usePostManualRoutine = (data: any) => {
  return axios.post(`${CHECKLIST_HANDELING_URL}/manualSchedule/`, data);
};

export const getChecklistCalendar = (
  siteId: string,
  startTime?: DateTime,
  endTime?: DateTime
) => {
  return axios.get<ChecklistPeriodState[]>(
    `${CREATION_URL}/site/${siteId}/deviations-calendar?startTime=${startTime.toUTC().toISO()}&endTime=${endTime.toUTC().toISO()}`
  );
};


export type ChecklistPeriodState = {
  checklistId: string;
  assignedId: string;
  name: string;
  periodStatus: ChecklistPeriodStatus;
  periodFrom: Date;
  periodTo: Date;
  calendarDeviations: ChecklistCalendarDeviation[];
  isScheduled: boolean;
}

export enum ChecklistPeriodStatus {
  Completed,
  HandledDeviations,
  UnhandledDeviations,
  Pending,
  NotCompleted
}

export enum PeriodDeviationStatus {
  Handled,
  Unhandled,
}

export type ChecklistCalendarDeviation = {
  type: string;
  timestamp: Date;
  deviationStatus: PeriodDeviationStatus;
}

export type ChecklistDeviations = {
  checklistId: string;
  assignedId: string;
  checkListName: string;
  periodDeviations: ChecklistPeriod[];
};

export type ChecklistPeriod = {
  periodStart: string;
  periodEnd: string;
  deviations: DeviationStatus[];
};

export type DeviationStatus = {
  type: string;
  pointId?: string;
  deviationId: string;
  comment: string;
  timestamp: string;
  sessionId: string;
  assignedId: string;
  signatureId?: string;

  handled: boolean;
  handledComment: string;
  handledSignatureId?: string;

  actionTaken: string;
  actionTakenSignatureId?: string;
};
